<template>
    <v-card flat class="">
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr>
						<th>Institusi</th>
						<td>: {{ data.nama_institusi }}</td>
						<td></td>
						<th>Program Studi</th>
						<td>: {{ data.nama_prodi }}</td>
					</tr>
                    <tr>
                        <th>Kelompok</th>
                        <td>: {{ data.profesi }}</td>
                        <td></td>
                        <th>Tanggal Awal</th>
                        <td>: {{ formatDate(data.tanggal_awal) }}</td>
                    </tr>
                    <tr>
                        <th>Stase</th>
                        <td>: {{ data.nama }}, {{ data.stase }}</td>
                        <td></td>
                        <th>Tanggal Akhir</th>
                        <td>: {{ formatDate(data.tanggal_akhir) }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-container fluid>
            <h3>Evaluasi Peserta Didik</h3>
        </v-container>
        <v-simple-table dense bord>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th rowspan="2" class="text-center">No</th>
                        <th rowspan="2" class="text-center">Nama</th>
                        <th rowspan="2" class="text-center">BST</th>
                        <th rowspan="2" class="text-center">DOPS</th>
                        <th rowspan="2" class="text-center">Referat/Jurnal Reading</th>
                        <th rowspan="2" class="text-center">Mini CEX</th>
                        <th rowspan="2" class="text-center">CBD</th>
                        <th colspan="5" class="text-center">Jaminan Mutu</th>
                        <th rowspan="2"></th>
                    </tr>
                    <tr>
                        <th class="text-center">Form Kepatuhan APD</th>
                        <th class="text-center">Form Kepatuhan Kebersihan Tangan</th>
                        <th class="text-center">Form SKP</th>
                        <th class="text-center">Form PKPO</th>
                        <th class="text-center">IKP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in table.items" :key="item.id">
                        <td>{{ idx + 1 }}</td>
                        <td>{{ item.nama }}</td>
                        <td class="text-center">{{ item.bst }}</td>
                        <td class="text-center">{{ item.dops }}</td>
                        <td class="text-center">{{ item.referat }}</td>
                        <td class="text-center">{{ item.mini_cex }}</td>
                        <td class="text-center">{{ item.cbd }}</td>
                        <td class="text-center">{{ item.mutu_kepatuhan_apd }}</td>
                        <td class="text-center">{{ item.mutu_kepatuhan_tangan }}</td>
                        <td class="text-center">{{ item.mutu_skp }}</td>
                        <td class="text-center">{{ item.mutu_pkpo }}</td>
                        <td class="text-center">{{ item.mutu_ikp }}</td>
                        <td>
                            <v-icon small class="mr-2" @click="viewItem(item)">
                                {{ icons.mdiEye }}
                            </v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog v-model="dialogView" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Lihat Catatan</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea readonly label="Catatan :" v-model="form.editedItem.catatan" dense outlined hide-details></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
// import PilihMultiUser from '@/views/PilihMultiUser.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiDelete, mdiPencil } from '@mdi/js'
const apiRoot = process.env.VUE_APP_APIROOT
export default {
    components: {
        // PilihMultiUser,
    },
    data() {
        return {
            praktik_id: this.$route.params.praktik_id,
            data: {
                nama_institusi: '',
				nama_prodi: '',
                profesi: '',
                stase: '',
                tanggal_awal: '',
                tanggal_akhir: '',
            },
            icons: {
                mdiDelete,
                mdiPencil,
                mdiEye
            },
            tableOptions: {},
            table: {
                loading: true,
                // headers: [
                //     { text: 'No', align: 'start', sortable: false, value: 'no', },
                //     { text: 'Waktu', value: 'waktu', sortable: false },
                //     { text: 'Kegiatan', value: 'kegiatan', sortable: false },
                //     { text: '', value: 'actions', sortable: false },
                // ],
                items: [],
                totalItems: 0,
            },
            dialog: false,
            dialogView: false,
            form: {
                editedIndex: -1,
                editedItem: {
                    id: '',
                    praktik_id: '',
                    user_id: '',
                    bst: '',
                    dops: '',
                    referat: '',
                    mini_cex: '',
                    cbd: '',
                    mutu_kepatuhan_apd: '',
                    mutu_kepatuhan_tangan: '',
                    mutu_skp: '',
                    mutu_pkpo: '',
                    mutu_ikp: '',
                },
                defaultItem: {
                    id: '',
                    praktik_id: '',
                    user_id: '',
                    bst: '',
                    dops: '',
                    referat: '',
                    mini_cex: '',
                    cbd: '',
                    mutu_kepatuhan_apd: '',
                    mutu_kepatuhan_tangan: '',
                    mutu_skp: '',
                    mutu_pkpo: '',
                    mutu_ikp: '',
                },
                pembimbings: [],
            },
        }
    },
    watch: {
        // tableOptions: {
        //     handler() {
        //         this.getDataFromApi()
        //     },
        //     deep: true,
        // },
    },
    computed: {
        formTitle() {
            return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
        },
    },
    created() {
        this.getDetail()
        this.getDataFromApi()
    },
    methods: {
        save() {
            const fmData = new FormData()
            // fmData.append('id', this.table.items[idx].id)
            // fmData.append('praktik_id', this.praktik_id)
            // fmData.append('user_id', this.table.items[idx].user_id)
            // fmData.append('bst', this.table.items[idx].bst)
            // fmData.append('dops', this.table.items[idx].dops)
            // fmData.append('referat', this.table.items[idx].referat)
            // fmData.append('mini_cex', this.table.items[idx].mini_cex)
            // fmData.append('cbd', this.table.items[idx].cbd)
            // fmData.append('mutu_kepatuhan_apd', this.table.items[idx].mutu_kepatuhan_apd)
            // fmData.append('mutu_kepatuhan_tangan', this.table.items[idx].mutu_kepatuhan_tangan)
            // fmData.append('mutu_skp', this.table.items[idx].mutu_skp)
            // fmData.append('mutu_pkpo', this.table.items[idx].mutu_pkpo)
            // fmData.append('mutu_ikp', this.table.items[idx].mutu_ikp)
            fmData.append('id', this.form.editedItem.id)
            fmData.append('praktik_id', this.praktik_id)
            fmData.append('user_id', this.form.editedItem.user_id)
            fmData.append('bst', this.form.editedItem.bst)
            fmData.append('dops', this.form.editedItem.dops)
            fmData.append('referat', this.form.editedItem.referat)
            fmData.append('mini_cex', this.form.editedItem.mini_cex)
            fmData.append('cbd', this.form.editedItem.cbd)
            fmData.append('mutu_kepatuhan_apd', this.form.editedItem.mutu_kepatuhan_apd)
            fmData.append('mutu_kepatuhan_tangan', this.form.editedItem.mutu_kepatuhan_tangan)
            fmData.append('mutu_skp', this.form.editedItem.mutu_skp)
            fmData.append('mutu_pkpo', this.form.editedItem.mutu_pkpo)
            fmData.append('mutu_ikp', this.form.editedItem.mutu_ikp)
            axiosPostAuth('api/Pembimbing/saveEvaluasiDokter', fmData)
                .then(response => {
                    if (response.code === 200) {
                        this.getDataFromApi()
                        this.dialog = false
                    }
                    else {
                        alert(response.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        getDataFromApi() {
            const fmData = new FormData()
            fmData.append('praktik_id', this.praktik_id)
            axiosPostAuth('api/Pembimbing/getEvaluasiDokter', fmData)
                .then(response => {
                    this.table.items = response.data
                    this.table.totalItems = response.total
                    this.table.loading = false
                })
                .catch(error => {
                    alert(error)
                })
        },
        add() {
            this.form.editedItem = Object.assign({}, this.form.defaultItem)
            this.form.editedIndex = -1
        },
        editItem(item) {
            this.form.editedIndex = this.table.items.indexOf(item)
            this.form.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        viewItem(item) {
            // this.form.editedIndex = this.table.items.indexOf(item)
            this.form.editedItem = Object.assign({}, item)
            this.dialogView = true
        },
        getDetail() {
            const fmData = new FormData()
            fmData.append('id', this.praktik_id)
            axiosPostAuth('api/Praktik/getPraktik', fmData)
                .then(response => {
                    this.data = Object.assign({}, response.data)
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>